import { Injectable } from '@angular/core';
import { Entity, EntityInterface } from '@proman/services/entity.service';
import { resourcesConfig } from '@proman/resources';
import { OrdersService } from './orders.service';
import { OrderEntityInterface } from '@proman/resources/order';
import { OrderProduct, Product } from '@proman/interfaces/entity-interfaces';
import { TableField } from '@proman/interfaces/object-interfaces';
import { Store } from '@ngrx/store';
import { getDecimalPlaces } from '@proman/store/system-options';
import { isDefinedNotNull, removeHtmlTags, roundNumber } from '@proman/utils';

@Injectable({ providedIn: 'root' })
export class OrderProductsService {
    orderProductEntity: EntityInterface;
    orderEntity: OrderEntityInterface;
    getExtraParameters = resourcesConfig['order_product'].params.extraParameters;
    decimalOrderProduct: number;
    decimalProductWeight: number;

    constructor(
        private Entity: Entity,
        private Orders: OrdersService,
        private store: Store
    ) {
        this.orderProductEntity = this.Entity.get('order_product');
        this.orderEntity = this.Entity.get('order') as OrderEntityInterface;
        this.store.select(getDecimalPlaces, 'order_product')
            .subscribe((value) => this.decimalOrderProduct = value ?? 2);
        this.store.select(getDecimalPlaces, 'product_weight')
            .subscribe((value) => this.decimalProductWeight = value ?? 2);

    }

    private setQuantity = (row: any, value: any, callback: any) => {
        this.Entity.get('order_product')
            .update({ id: row.id, readyForShipmentQuantity: value })
            .then(callback);
    };

    private getRemnant = (row: any) => {
        return row.quantity - row.readyForShipmentQuantity;
    };

    getEntity = (): any => {
        return this.orderProductEntity;
    };

    getFields = (callback: any): TableField[] => {
        return [
            {
                name: 'name',
                key: 'product.name',
                maxLength: 50,
                state: {
                    name: 'Product',
                    key: 'productId',
                    id: 'product.id'
                }
            },
            {
                name: 'product_id',
                key: 'product.id'
            },
            {
                name: 'order_id',
                key: 'order.id'
            },
            {
                name: 'order_name',
                key: 'order.name',
                state: {
                    name: 'OrderInfo',
                    key: 'orderId',
                    id: 'order.id'
                }
            },
            {
                name: 'lot_number',
                key: 'product.lotNumber',
                hidden: true,
            },
            {
                name: 'customer',
                key: 'order.customer.name'
            },
            {
                name: 'customer_number',
                key: 'order.customerNumber'
            },
            {
                name: 'vat',
                key: 'product.vat.name',
                extraJoins: ['product.vat'],
            },
            {
                name: 'weight',
                key: 'product.weight',
                formatter: 'numeric',
                getValue: (product: OrderProduct) => roundNumber(+product.product?.weight * +product.quantity, this.decimalProductWeight),
                stats: ['sum'],
                extraJoins: ['product'],
            },
            {
                name: 'manager',
                key: 'order.manager.name'
            },
            {
                name: 'order_status',
                key: 'order.status',
                getValue: (row: OrderProduct) => this.Orders.getOrderStatus(row.order),
                formatter: 'compile',
                filter: {
                    type: 'dropdown',
                    options: this.Orders.getStatuses()
                }
            },
            {
                name: 'created_at',
                key: 'order.createdAt',
                formatter: 'dateTime',
                formatterConfig: '_short_datetime_js',
                showTime: true
            },
            {
                name: 'order_finish_date',
                key: 'order.finishedAt',
                formatter: 'dateTime',
                formatterConfig: '_short_datetime_js',
                hidden: true,
            },
            {
                name: 'categories',
                key: 'product.article.categories.name',
                getValue: (product: OrderProduct) => product.product?.article?.categories?.map((category) => category?.name).join(', '),
                hidden: true,
                extraJoins: ['product.article.categories'],
            },
            {
                name: 'order_type',
                key: 'order.type.name'
            },
            {
                name: 'quantity',
                key: 'quantity',
                formatter: 'numeric',
                formatterConfig: 0,
                stats: ['sum']
            },
            {
                name: 'discount',
                key: 'discountSum',
                formatter: 'money',
                formatterConfig: 2,
                stats: ['sum']
            },
            {
                name: 'order_parameters',
                key: 'order.parameters',
                filter: {
                    type: 'search',
                    keys: ['order.parameters.parameter.name', 'order.parameters.parameter.alias', 'order.parameters.value'],
                },
                formatter: 'parameters',
                hidden: true,
                extraJoins: [
                    'order.parameters',
                    'order.parameters.parameter',
                ],
                preloadParametersData: true
            },
            {
                name: 'product_parameters',
                key: 'product.parameters',
                filter: {
                    type: 'search',
                    keys: ['product.parameters.parameter.name', 'product.parameters.parameter.alias', 'product.parameters.value'],
                },
                formatter: 'parameters',
                hidden: true,
                extraJoins: [
                    'product.parameters',
                    'product.parameters.parameter',
                ],
                preloadParametersData: true
            },
            {
                name: 'ready_for_shipment_quantity',
                key: 'readyForShipmentQuantity',
                callback: (row: any, value: any) => this.setQuantity(row, value, callback),
                formatter: 'input',
                stats: ['sum']
            },
            {
                name: 'shipped_quantity',
                key: 'shippedQuantity',
                formatter: 'numeric',
                stats: ['sum']
            },
            {
                name: 'left_to_be_made',
                key: 'remnant',
                getValue: this.getRemnant,
                formatter: 'numeric',
                formatterConfig: 0,
                stats: ['sum']
            },
            {
                name: 'price',
                key: 'customerPrice',
                stats: ['sum'],
                getValue: (item: OrderProduct) => {
                  return (item.product && item.product.salePrice && +item.product?.salePrice?.amount !== 0) ? item.product?.salePrice : item.customerPrice;
                },
                formatter: 'money',
                formatterConfig: this.decimalOrderProduct,
            },
            {
                name: 'warehouse',
                key: 'product.warehouseLocation.warehouse.name',
                getValue: (item: OrderProduct) => item.product?.warehouseLocation?.warehouse?.name || '',
            },
            {
                name: 'store_name',
                key: 'product.storeName',
                hidden: true
            },
            {
                name: 'capacity',
                key: 'product.capacity',
                filter: {
                    type: 'search',
                    keys: ['product.capacity'],
                },
                hidden: true,
                getValue: (item: OrderProduct) => item.product?.capacity,
            },
            {
                name: 'extent',
                key: 'product.extent',
                filter: {
                    type: 'search',
                    keys: ['product.extent'],
                },
                hidden: true,
                getValue: (item: OrderProduct) => item.product?.extent,
            },
            {
                name: 'comments',
                key: 'product.customerComments',
                getValue: (row: OrderProduct) => row.product && isDefinedNotNull(row.product.customerComments) ? removeHtmlTags(row.product.customerComments) : ''
            },
            {
                name: 'validity',
                key: 'product.validity',
                translate: true
            },
        ];
    };

    getCustomFilters = () => this.Orders.getCustomFilters('order.');

}
